/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GlobalDataContext } from "../../context/context";
import "react-tabs/style/react-tabs.css";

const Map = ({ title }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        <h2 className="text-center">
          We Cover:
        </h2>

        <div className="w-4/5 mx-auto py-10">
        <Tabs>
          <TabList>
            {rpdata?.dbPrincipal?.location?.slice(1, 6).map((item, index) => {
              return <Tab>{item.address}</Tab>;
            })}
          </TabList>
          {rpdata?.dbPrincipal?.location?.slice(1, 6).map((item, index) => {
            return (
              <TabPanel>
                <iframe
                  src={item.url}
                  style={{ width: "100%", height: "400px" }}
                />
              </TabPanel>
            );
          })}
        </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Map;
